import Layout from "../components/layout"
import React from "react"
import { Col, Container } from "styled-bootstrap-grid"
import {
  ParagraphContainer,
  ParagraphsRow,
  ServicesRow,
  CardLine,
  CardContent,
  CardTitle,
  Card,
  ServicesGraph,
  Paragraph1,
  Title,
  ServicesContent,
  ServicesWrapper,
} from "../styles/services/style"

const Services = () => {
  return (
    <Layout>
      <ServicesWrapper>
        <ServicesContent>
          <Title>Our services and process page.</Title>
          <Paragraph1>
            How we deliver a great customer experience, profit and growth.
          </Paragraph1>
          <Container fluid>
            <ServicesRow>
              <Col lg={6}>
                <ServicesGraph src="/services.png" />
              </Col>
              <Col lg={6}>
                <ParagraphContainer fluid>
                  <ParagraphsRow>
                    <Col sm={6}>
                      <Card>
                        <CardTitle color="#DB0613">Branding</CardTitle>
                        <CardContent>
                          <CardLine>Brand Strategy</CardLine>
                          <CardLine>Brand DNA</CardLine>
                          <CardLine>Multi-sensory</CardLine>
                          <CardLine>Brand Identity</CardLine>
                          <CardLine>Graphic Design</CardLine>
                          <CardLine>Communications</CardLine>
                          <CardLine>Brand Architecture</CardLine>
                          <CardLine>Brand Guidelines</CardLine>
                        </CardContent>
                      </Card>
                    </Col>
                    <Col sm={6}>
                      <Card>
                        <CardTitle color="#0097DB">People</CardTitle>
                        <CardContent>
                          <CardLine>Service Training</CardLine>
                          <CardLine>Brand Behaviour</CardLine>
                          <CardLine>Brand Engagement</CardLine>
                          <CardLine>Communication Tools</CardLine>
                          <CardLine>Brand Culture</CardLine>
                          <CardLine>Change Management</CardLine>
                          <CardLine>Service Culture</CardLine>
                        </CardContent>
                      </Card>
                    </Col>
                    <Col sm={6}>
                      <Card>
                        <CardTitle color="#D6D400">Environment</CardTitle>
                        <CardContent>
                          <CardLine>Retail Strategy</CardLine>
                          <CardLine>Customer Journeys</CardLine>
                          <CardLine>Customer Experience Design</CardLine>
                          <CardLine>Kit of Parts</CardLine>
                          <CardLine>Branch Formats</CardLine>
                          <CardLine>Branch Assessment</CardLine>
                          <CardLine>Interior Design</CardLine>
                          <CardLine>Segmentation</CardLine>
                          <CardLine>3rd Party Strategies</CardLine>
                        </CardContent>
                      </Card>
                    </Col>
                    <Col sm={6}>
                      <Card>
                        <CardTitle color="#EB8A00">Technology</CardTitle>
                        <CardContent>
                          <CardLine>Digital Strategy</CardLine>
                          <CardLine>Web Portal Design</CardLine>
                          <CardLine>Visual and Interactive Design</CardLine>
                          <CardLine>Innovation</CardLine>
                          <CardLine>Mobile Channels Interface</CardLine>
                          <CardLine>Digital Marketing</CardLine>
                          <CardLine>New Technologies</CardLine>
                          <CardLine>Digital Assessment</CardLine>
                        </CardContent>
                      </Card>
                    </Col>
                  </ParagraphsRow>
                </ParagraphContainer>
              </Col>
            </ServicesRow>
          </Container>
        </ServicesContent>
      </ServicesWrapper>
    </Layout>
  )
}

export default Services
